import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import '../Header/style.css';
import ToggleIcon from '../../icons/ToggleIcon';
import CloseIcon from '../../icons/CloseIcon';
import { Link } from "react-router-dom";

function Page404() {
	const [winHeight, setWinHeight] = useState(null);

	window.addEventListener('resize', function () {
		setWinHeight(window.innerHeight);
	})

	useEffect(() => {
		setWinHeight(window.innerHeight);
	}, [])

	useEffect(() => {
		document.querySelector('.navigation__links').style.setProperty('--vh', winHeight * 0.01 + 'px');
	}, [winHeight])

	const [toggle, setToggle] = useState(false);
	const toggleClick = () => {
		setToggle(!toggle);
		if (document.querySelector('body').classList.contains('fixed')) {
			document.querySelector('body').classList.remove('fixed');
			document.querySelector('html').classList.remove('fixed');
		} else {
			document.querySelector('body').classList.add('fixed');
			document.querySelector('html').classList.add('fixed');
		}
	}
	const linkClick = () => {
		setToggle(false);
		if (document.querySelector('body').classList.contains('fixed')) {
			document.querySelector('body').classList.remove('fixed');
			document.querySelector('html').classList.remove('fixed');
		}
	}

	const footer = useRef();

	return (
		<div className='page-404'>
			<div className='navigation'>
				<picture className='navigation__logo'>
					<img src="/img/logo.svg" alt="" />
				</picture>
				<div className={`navigation__links ${toggle ? 'active' : ''}`}>
					<Link to='/' state={{ link: 'system-info' }} >
						<span onClick={() => linkClick()}>Сведения о системе</span>
					</Link>
					<Link to='/' state={{ link: 'goals' }} >
						<span onClick={() => linkClick()}>Цели и ценности проекта</span>
					</Link>
					<Link to='/' state={{ link: 'results' }} >
						<span onClick={() => linkClick()}>Результат использования</span>
					</Link>
					<Link to='/' state={{ link: 'functions' }} >
						<span onClick={() => linkClick()}>Модули</span>
					</Link>
					<Link to='/' state={{ link: 'users' }} >
						<span onClick={() => linkClick()}>Стоимость</span>
					</Link>
					<Link to='/' state={{ link: 'contacts' }} >
						<span onClick={() => linkClick()}>Контакты</span>
					</Link>
					<Link to='/' state={{ link: 'questions' }} className='w-100 get-consultation d-lg-none btn-yellow'>
						<span onClick={() => linkClick()}>
							Получить консультацию
						</span>
					</Link>
				</div>
				<button
					className={`navigation__menu ${toggle ? 'active' : ''}`}
					onClick={() => toggleClick()}
				>
					<ToggleIcon />
					<CloseIcon />
				</button>
			</div>
			<div className='page-404__content-wrap'>
				<div className='page-404__content'>
					<div className='page-404__text-block'>
						<p className='page-404__text'>Страница не найдена</p>
					</div>
					<div className='page-404__button'>
						<Link to='/' >
							<span className="get-consultation btn-yellow" onClick={() => linkClick()} >
								Перейти на главную
							</span>
						</Link>
					</div>
				</div>
			</div>
			<div className='page-404__footer'>
				<p className='page-404__copyright'>
					&#169; 2023
				</p>
				<a className='page-404__policy' target='_blank' href='/Политика_конфиденциальности_САТМ_лендинг.pdf'>
					Политика обработки персональных данных
				</a>
			</div>
		</div>
	)
}

export default Page404