import React from 'react'
import './style.css'

export default function Results() {
	return (
		<div className='landing-results'>
			<div className='landing-results__padding' id='results'></div>
			<h2 className="landing-results__header">
				Ожидаемые результаты и эффективность реализации Системы
			</h2>
			<div className="statistics">
				<div className="statistics__line">
					<div className="statistics__result">Исключение транспортных расходов на сотрудников, связанных с необходимостью обучения и аттестации</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-100" />
						<div className="statistics__number">100%</div>
					</div>
				</div>
				<div className="statistics__line">
					<div className="statistics__result">Охват всех видов работ, учёт опасных факторов и специфики места производства работ</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-100" />
						<div className="statistics__number">100%</div>
					</div>
				</div>
				<div className="statistics__line">
					<div className="statistics__result">Минимизация рисков, исключение нарушений при организации и производстве работ</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-75" />
						<div className="statistics__number">75%</div>
					</div>
				</div>
				<div className="statistics__line">
					<div className="statistics__result">Сокращение затрат на проведение инструктажей</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-70" />
						<div className="statistics__number">70%</div>
					</div>
				</div>
				<div className="statistics__line">
					<div className="statistics__result">Снижение затрат на обязательное обучение персонала</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-60" />
						<div className="statistics__number">60%</div>
					</div>
				</div>
				<div className="statistics__line">
					<div className="statistics__result">Сокращение общего времени подготовки к производству работ</div>
					<div className="statistics__percents">
						<div className="statistics__fill-line landing-percent-60" />
						<div className="statistics__number">60%</div>
					</div>
				</div>
			</div>
		</div>
	)
}
