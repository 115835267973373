import React from 'react'
import './style.css'

export default function SystemInfo() {
	return (
		<div className='landing-system-info'>
			<div className='landing-system-info__padding' id="system-info"></div>
			<h2 className='landing-system-info__header landing-header'>Общие сведения о системе</h2>
			<p className='landing-system-info__info'>Данная программа имеет особое значение для отраслевых предприятий. Позволяет конечным пользователям усилить производственный контроль, автоматизировать процессы в области производственной безопасности.</p>
			<div className='info-circles'>
				<div className="info-circles__row">
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-1.svg" />
						</svg>
						<p className="info-circles__text column-1">Оптимизация временных и финансовых затрат на процесс ведения документов по производственной безопасности</p>
					</div>
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-2.svg" />
						</svg>
						<p className="info-circles__text column-2">Своевременное обучение<br /> и аттестация персонала без отрыва от производства</p>
					</div>
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-3.svg" />
						</svg>
						<p className="info-circles__text column-3">Электронный наряд-допуск, онлайн инструктажи</p>
					</div>
				</div>
				<div className="info-circles__row">
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-4.svg" />
						</svg>
						<p className="info-circles__text column-1">Интеграция с внешними<br />системами (обучение)</p>
					</div>
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-5.svg" />
						</svg>
						<p className="info-circles__text column-2">Контроль соблюдения<br />законодательства в сфере производственной безопасности</p>
					</div>
					<div className="info-circles__item">
						<svg className="info-circles__icon">
							<image href="/img/system-info-6.svg" />
						</svg>
						<p className="info-circles__text column-3">Функциональная<br />мобильная версия</p>
					</div>
				</div>
			</div>
		</div>
	)
}
