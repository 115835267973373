import React, {useRef} from 'react'
import './style.css'

export default function Footer() {
	const footer = useRef();

	return (
		<div className="landing-footer" id='contacts' rel={footer}>
			<div className='landing-footer__content'>
				<div className='landing-footer__logo'>
					<picture className='navigation__logo'>
						<img src="/img/logo.svg" alt="" />
					</picture>
					<div className='landing-footer__name'>
						<div className='landing-footer__top-name'>
							Инновационная платформа
						</div>
						<div className='landing-footer__bottom-name'>
							«Control Automated Technological Methodology»
						</div>
					</div>
				</div>
				<div className='landing-footer__contacts'>
					<div className='landing-footer__numbers'>
						<div className='landing-footer__number'>
							<svg>
								<image href='/img/phone.svg' />
							</svg>
							<a className='landing-footer__link' href="tel:+78001012486">8 (800) 101-24-86</a>
						</div>
						<div className='landing-footer__number'>
							<svg>
								<image href='/img/phone.svg' />
							</svg>
							<a className='landing-footer__link' href="tel:+79011562424">8 (901) 156-24-24</a>
						</div>
					</div>
					<div className='landing-footer__emails'>
						<div className='landing-footer__email'>
							<svg>
								<image href='/img/mail.svg' />
							</svg>
							<a className='landing-footer__link' href="mailto:info@catm.tech">info@catm.tech</a>
						</div>
					</div>
				</div>
			</div>
			<div className='landing-footer__bottom'>
				<p className='landing-footer__copyright'>&#169; { (new Date()).getFullYear() }</p>
				<a className='landing-footer__privacy-policy' target='_blank' href='/Политика_конфиденциальности_САТМ_лендинг.pdf'>Политика обработки персональных данных</a>
			</div>
		</div>
	)
}