// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/up.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-up {
	display: none;
	width: 40px;
	height: 40px;
	position: fixed;
	z-index: 5;
	right: 53px;
	bottom: 53px;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.arrow-up-show {
	display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,WAAW;CACX,YAAY;CACZ,eAAe;CACf,UAAU;CACV,WAAW;CACX,YAAY;CACZ,yDAA2C;CAC3C,0BAA0B;CAC1B,4BAA4B;AAC7B;;AAEA;CACC,cAAc;AACf","sourcesContent":[".arrow-up {\n\tdisplay: none;\n\twidth: 40px;\n\theight: 40px;\n\tposition: fixed;\n\tz-index: 5;\n\tright: 53px;\n\tbottom: 53px;\n\tbackground-image: url('/public/img/up.svg');\n\tbackground-size: 100% 100%;\n\tbackground-repeat: no-repeat;\n}\n\n.arrow-up-show {\n\tdisplay: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
