import React from 'react';

import './style.css';

export default function Purpose() {
	const hidePreview = () => {
		document.getElementById('video-image').style.display = 'none';
		document.getElementById('video-play').style.display = 'none';
		document.getElementById('landing-video').style.border = 'none';
		document.getElementById('video').play();
	}
	return (
		<div className="landing-purpose-wrap">
			<div className="landing-purpose-wrap__bottom" />
			<div className="landing-purpose">
				<div className="landing-purpose__info">
					<h2 className="landing-purpose__header landing-header">Назначение Системы</h2>
					<div className="landing-purpose__text">Система представляет собой программный комплекс, состоящий из Web-платформы и мобильного приложения, подходящий большинству крупных отраслевых предприятий страны, имеющих в своем составе удалённые подразделения и объекты с большим количеством опасных работ и исполнителей.</div>
					<div className="landing-purpose__list">
						<div className="landing-purpose__list-item">Автоматизация процессов</div>
						<div className="landing-purpose__list-item">Экономия трудозатрат</div>
						<div className="landing-purpose__list-item">Усиление контроля в области производственной безопасности</div>
						<div className="landing-purpose__list-item">Соблюдение законодательства РФ</div>
					</div>
					<div className='landing-video' id='landing-video'>
						<div className='landing-video__wrap'>
							<video src='/video/catm-video.mp4' className='landing-video__video' id='video' width={'100%'} controls></video>
							<img src='/img/landing-video.jpg' className='landing-video__preview-img' id='video-image'/>
							<img src='/img/landing-play-button.svg' className='landing-video__play' id='video-play' onClick={() => hidePreview()}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
