import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Page404 from "./components/Page404/Page404";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/*" element={<Page404 />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
