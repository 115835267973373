import React, { useEffect } from 'react'
import About from '../components/About/About';
import Functions from "../components/Functions/Functions";
import Goals from "../components/Goals/Goals";
import Header from "../components/Header/Header";
import Partners from "../components/Partners/Partners";
import Purpose from "../components/Purpose/Purpose";
import Questions from "../components/Questions/Questions";
import Results from "../components/Results/Results";
import SystemInfo from "../components/SystemInfo/SystemInfo";
import Users from "../components/Users/Users";
import Footer from "../components/Footer/Footer";
import { useLocation } from 'react-router-dom';
import './styles.css';

export default function Index() {

	let { state } = useLocation();

	const loadFunction = (link) => {
		const el = document.getElementById(link);
		console.log(el)
		el.scrollIntoView(true, { behavior: 'smooth' })
		window.history.replaceState(state, '', '')
	}
	
	useEffect(() => {
		if (state != null) {
			if (state.link != '' || state.link != null) {
				window.onload = loadFunction(state.link);
			}
		}
	})

	window.addEventListener('scroll', () => {
		const scrollY = window.scrollY || document.documentElement.scrollTop;
		const arrow = document.getElementById('arrow-up');
		scrollY > 200 ? arrow.classList.add('arrow-up-show') : arrow.classList.remove('arrow-up-show');
	});

	return (
		<>
			<a href='#' className='arrow-up' id='arrow-up'></a>
			<Header />
			<About />
			<SystemInfo />
			<Goals />
			<Purpose />
			<Results />
			<Functions />
			<Users />
			<Partners />
			<Questions />
			<Footer />
		</>
	)
}
