import React from 'react';
import './style.css';

export default function Users() {
	return (
		<div className="landing-users">
			<div className="landing-users__padding" id="users"></div>
			<h2 className="landing-users__header">
				Стоимость
			</h2>
			<div className="landing-users__sub-header">
				Тарифы CATM
			</div>
			<div className='landing-users__tariffs-block'>
				<div className="landing-users__tariff-wrap">
					<div className="landing-users__tariff">
						<div className="landing-users__users">до 1 000 пользователей</div>
						<div className="landing-users__price">2 000 &#8381;<br />за пользователя / год</div>
						<div className="landing-users__vat">(НДС не облагается)</div>
					</div>
				</div>
				<div className="landing-users__tariff-wrap">
					<div className="landing-users__tariff">
						<div className="landing-users__users">от 1 001 пользователей</div>
						<div className="landing-users__price">Индивидуальные<br />условия</div>
					</div>
				</div>
			</div>
			{/* <div className="landing-users__sub-header">
				Документы
			</div>
			<div className="landing-users__documents row">
				<a href="/Свидетельство.pdf" className="landing-users__document col-xl-3 col-lg-4 col-md-6" target='_blank'>
					<svg>
						<image href="/img/pdf.svg" />
					</svg>
					<div className="landing-users__doc-name">Свидетельство</div>
				</a>
			</div> */}
		</div>
	)
}
