import React from 'react'
import './style.css'

export default function About() {
	return (
		<div className='landing-system-info about-company'>
			<div className='landing-system-info__padding' id="about-company"></div>
			<h2 className='landing-system-info__header landing-header'>О компании</h2>
			<p>Компания ООО «КАТМ» основана 17.03.2023, основной вид деятельности по ОКВЭД 62.01 Разработка компьютерного программного обеспечения.</p>
			<p>Компания занимается разработкой и реализацией современных решений по автоматизации бизнес процессов в области производственной безопасности.</p>
			<p>Основной проект компании «Контроль Автоматизированный. Технологическая Методология» CATM ®</p>
			<p>Проект несет в себе универсальное решение для отраслевых предприятий, позволяющее усилить производственный контроль и автоматизировать процессы в области производственной безопасности:</p>
			<ul>
				<li>Сокращение общего времени подготовки к производству;</li>
				<li>Уход от непроизводственных простоев;</li>
				<li>Минимизация рисков, исключение нарушений при организации и производстве работ;</li>
				<li>Повышение уровня знаний работников и уменьшение риска травматизма;</li>
				<li>Усиление производственного контроля;</li>
				<li>Эффективное управление данными.</li>
			</ul>
		</div>
	)
}
