import React, { useState, useEffect } from 'react'
import './style.css'

export default function Goals() {

	const [change, setChange] = useState(1);

	return (
		<div className="landing-goals">
			<div className="landing-goals__line" />
			<div className="landing-goals__padding"  id='goals'></div>
			<div className="landing-goals__wrap">
				<h2 className="landing-goals__header landing-header">Цели и ценности проекта</h2>
				<p className="landing-goals__text">Обеспечение высокого уровня оптимизации производственных процессов при организации и осуществлении работ повышенной опасности. Проект несет в себе большой потенциал для развития компании, повышения культуры безопасности и извлечения материальной выгоды.</p>
				<div className="landing-goals__circles-line">
					<CircleWrap
						id={1}
						title='Сокращение общего времени подготовки к производству'
						change={change}
						setChange={setChange}
					/>
					<CircleWrap
						id={2}
						title='Уход от непроизводственных простоев'
						change={change}
						setChange={setChange}
					/>
					<CircleWrap
						id={3}
						title='Минимизация рисков, исключение нарушений при организации и производстве работ'
						change={change}
						setChange={setChange}
					/>
					<CircleWrap
						id={4}
						title='Повышение уровня знаний работников, следовательно - уменьшение риска травматизма'
						change={change}
						setChange={setChange}
					/>
					<CircleWrap
						id={5}
						title='Усиление производственного контроля'
						change={change}
						setChange={setChange}
					/>
					<CircleWrap
						id={6}
						title='Эффективное управление данными'
						change={change}
						setChange={setChange}
					/>
				</div>
			</div>
		</div>
	)
}


const CircleWrap = (props) => {
	const {
		id,
		change,
		setChange,
		title,
	} = props

	const [active, setActive] = useState(false)

	const onHover = () => {
		if (id !== change) {
			setActive(true);
			setChange(id);
		}
	}

	useEffect(() => {
		if (id === change) {
			setActive(true);
		} else {
			setActive(false);
		}
	}, [change])


	return (
		<div
			className={`landing-goals__circle-wrap-${id}`}
			onMouseOver={() => onHover()}
		>
			<div className={`landing-goals__circle ${active ? 'active' : ''}`}>
				<svg className="landing-goals__circles">
					<image href="/img/goals-circles.svg" />
				</svg>
				<div className="landing-goals__circle-text">
					{title}
				</div>
			</div>
		</div>
	)
}