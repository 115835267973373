import React, { useState, useEffect } from 'react'
import './style.css'
import ToggleIcon from '../../icons/ToggleIcon'
import CloseIcon from '../../icons/CloseIcon'

export default function Header() {

	const [toggle, setToggle] = useState(false);
	const toggleClick = () => {
		setToggle(!toggle);
		if (document.querySelector('body').classList.contains('fixed')) {
			document.querySelector('body').classList.remove('fixed');
			document.querySelector('html').classList.remove('fixed');
		} else {
			document.querySelector('body').classList.add('fixed');
			document.querySelector('html').classList.add('fixed');
		}
	}
	const linkClick = () => {
		setToggle(false);
		if (document.querySelector('body').classList.contains('fixed')) {
			document.querySelector('body').classList.remove('fixed');
			document.querySelector('html').classList.remove('fixed');
		}
	}
	const [winHeight, setWinHeight] = useState(null);
	window.addEventListener('resize', function () {
		setWinHeight(window.innerHeight);
	})
	useEffect(() => {
		setWinHeight(window.innerHeight);
	}, [])
	useEffect(() => {
		document.querySelector('.navigation__links').style.setProperty('--vh', winHeight * 0.01 + 'px');
	}, [winHeight])

	return (
		<div className='landing-top'>
			<div className='navigation'>
				<picture className='navigation__logo'>
					<img src="/img/logo.svg" alt="" />
				</picture>
				<div className={`navigation__links ${toggle ? 'active' : ''}`}>
					<a href="#about-company" onClick={() => linkClick()}>О компании</a>
					<a href="#system-info" onClick={() => linkClick()}>Сведения о системе</a>
					<a href="#goals" onClick={() => linkClick()}>Цели и ценности проекта</a>
					<a href="#results" onClick={() => linkClick()}>Результат использования</a>
					<a href="#functions" onClick={() => linkClick()}>Модули</a>
					<a href="#users" onClick={() => linkClick()}>Стоимость</a>
					<a href="#contacts" onClick={() => linkClick()}>Контакты</a>
					<a className="get-consultation d-lg-none btn-yellow" href="#questions" onClick={() => linkClick()}>
						Получить консультацию
					</a>
				</div>
				<button
					className={`navigation__menu ${toggle ? 'active' : ''}`}
					onClick={() => toggleClick()}
				>
					<ToggleIcon />
					<CloseIcon />
				</button>
			</div>
			<div className={`modern-solutions ${toggle ? 'active' : ''}`}>
				<h1 className="modern-solutions__header">
					Современные решения автоматизации процессов производственной безопасности
				</h1>
				<div className="modern-solutions__name">«Control Automated Technological Methodology»</div>
				<a className="get-consultation btn-yellow" href="#questions">
					Получить консультацию
				</a>
				<img src="/img/computer.svg" alt="" className="modern-solutions__computer" />
				<div className="characteristics">
					<div className="characteristics__item">Управление данными</div>
					<div className="characteristics__item">Эффективность</div>
					<div className="characteristics__item">Оперативность</div>
				</div>
			</div>
		</div>
	)
}
