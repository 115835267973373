import { axiosInstance } from "./API";

export function setEmail(json) {
	return axiosInstance.post('/email', json, {
		headers: {
			"Content-Type": "application/json",
		}
	}
	)
}

// export function getTest() {
// 	return axiosInstance.get(`/companies/?page=1`)
// }