import React, { useState, useEffect, useRef } from 'react'
import { setEmail } from '../../email.service'
import './style.css'
import Modal from '../Modal/Modal';

export default function Questions() {

	const nameInp = useRef(null);
	const [nameValue, setNameValue] = useState('');
	const telInp = useRef(null);
	const [telValue, setTelValue] = useState('');
	const emailInp = useRef(null);
	const [emailValue, setEmailValue] = useState('');
	const textareaInp = useRef(null);
	const [textareaValue, setTextareaValue] = useState('');
	const [agree, setAgree] = useState(false);
	const [active, setActive] = useState(false);
	const [mailError, setMailError] = useState(false);
	const [modalView, setModalView] = useState(false);
	const validateMail =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const emailCheck = (value) => value?.toLowerCase().match(validateMail);

	const sendMail = async function (name, number, email, question) {
		if ((name?.length > 0) && ((number?.length > 0) || ((email?.length > 0) && (emailCheck(email))))) {
			let num;
			if (number.length === 0) {
				num = null;
			} else {
				num = number
			}
			let mail;
			if (email.length === 0) {
				mail = null;
			} else {
				mail = email
			}
			const jsonData = {
				name: name,
				phone_number: num,
				email: mail,
				question: question
			}
			const res = await setEmail(JSON.stringify(jsonData));
			if (res !== undefined) {
				setNameValue('');
				nameInp.current.value = '';
				setTelValue('');
				telInp.current.value = '';
				setEmailValue('');
				emailInp.current.value = '';
				setTextareaValue('');
				textareaInp.current.value = '';
				setModalView(true);
			}
			else {
				setMailError(true);
			}
		} else if (!(emailCheck(email))) {
			setMailError(true);
		}
	}

	useEffect(() => {
		if (agree && ((telValue.length > 0) || (emailValue.length > 0)) && (nameValue.length > 0) && !modalView) {
			setActive(true);
		} else {
			setActive(false);
		}
		setMailError(false);
	}, [agree, nameValue, telValue, emailValue])

	return (
		<div className="landing-questions" id='questions'>
			<h2 className="landing-questions__header">
				Остались вопросы?
			</h2>
			<div className="landing-questions__text">
				Оставьте заявку, и наши специалисты ответят на интересующие Вас вопросы.
			</div>
			<div className="landing-questions__personal-info">
				<div className={`landing-questions__input-body ${nameValue !== '' ? 'active' : ''}`}>
					<input
						type="text"
						className="landing-questions__field"
						ref={nameInp}
						onChange={() => {
							setNameValue(nameInp.current.value);
						}}
					/>
					<div className='landing-questions__placeholder'>Ваше имя <span>*</span></div>
				</div>
				<div className={`landing-questions__input-body ${telValue !== '' ? 'active' : ''}`}>
					<input
						type="text"
						className="landing-questions__field"
						ref={telInp}
						onChange={() => {
							setTelValue(telInp.current.value);
						}}
					/>
					<div className='landing-questions__placeholder'>Номер телефона <span>*</span></div>
				</div>
				<div className={`landing-questions__input-body ${emailValue !== '' ? 'active' : ''}`}>
					<input
						type="text"
						className="landing-questions__field"
						ref={emailInp}
						onChange={() => {
							setEmailValue(emailInp.current.value);
						}}
					/>
					<div className='landing-questions__placeholder'>E-mail <span>*</span></div>
					{mailError ? <div className='error'>Введите корректный E-mail</div> : null}
				</div>
			</div>
			<div className='landing-questions__question'>
				<div className={`landing-questions__input-body ${textareaValue !== '' ? 'active' : ''}`}>
					<textarea
						className="landing-questions__textarea"
						ref={textareaInp}
						onChange={() => {
							setTextareaValue(textareaInp.current.value);
						}}
					/>
					<div className='landing-questions__placeholder landing-questions__placeholder--textarea'>Ваш вопрос</div>
				</div>
			</div>
			<div className='landing-questions__agreement'>
				<label className='landing-questions__checkbox-label-wrap'>
					<input
						type="checkbox"
						className="landing-questions__checkbox"
						id="personal-info"
						onChange={() => setAgree(!agree)}
					/>
					<div className='landing-questions__checkbox-label' />
				</label>
				<div className='landing-questions__label'>Я даю <a href='/Политика_конфиденциальности_САТМ_лендинг.pdf' target='_blank'>согласие на обработку персональных данных</a></div>
				<div className='landing-questions__checkbox'></div>
			</div>
			<button
				className={`get-consultation btn-yellow ${!active ? 'disable' : ''}`}
				onClick={() => sendMail(nameValue, telValue, emailValue, textareaValue)}
			>
				Получить консультацию
			</button>
			{modalView ?
				<Modal setModalView={setModalView} />
				: null}
		</div>
	)
}
