// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-message {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 288px;
	padding: 24px;
	background-color: #ffffff;
	box-shadow: 0px -1px 10px 6px rgba(34, 60, 80, 0.26);
	border-radius: 10px;
}

.modal-message__title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	margin-bottom: 24px;
	line-height: calc(28/20);
}

.modal-message__btn {
	width: 100%;
	font-size: 16px;
	line-height: 150%;
	padding: 12px;
	border-radius: 50px;
	border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/style.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,QAAQ;CACR,SAAS;CACT,gCAAgC;CAChC,YAAY;CACZ,aAAa;CACb,yBAAyB;CACzB,oDAAoD;CACpD,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,eAAe;CACf,kBAAkB;CAClB,mBAAmB;CACnB,wBAAwB;AACzB;;AAEA;CACC,WAAW;CACX,eAAe;CACf,iBAAiB;CACjB,aAAa;CACb,mBAAmB;CACnB,YAAY;AACb","sourcesContent":[".modal-message {\n\tposition: fixed;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\twidth: 288px;\n\tpadding: 24px;\n\tbackground-color: #ffffff;\n\tbox-shadow: 0px -1px 10px 6px rgba(34, 60, 80, 0.26);\n\tborder-radius: 10px;\n}\n\n.modal-message__title {\n\tfont-weight: 600;\n\tfont-size: 20px;\n\ttext-align: center;\n\tmargin-bottom: 24px;\n\tline-height: calc(28/20);\n}\n\n.modal-message__btn {\n\twidth: 100%;\n\tfont-size: 16px;\n\tline-height: 150%;\n\tpadding: 12px;\n\tborder-radius: 50px;\n\tborder: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
