import React, { useState, useEffect } from 'react'
import './style.css'

export default function Functions() {

	const [choise, setChoise] = useState(0);

	const tabsText = [
		{
			title: 'Личный QR-код',
			caption: 'Личный код позволит в кратчайшие сроки увидеть всю информацию по работнику необходимую для оценки его уровня образования для выполнения поставленных задач.',
		},
		{
			title: 'Обучение',
			caption: 'Данный модуль позволит предприятию организовать автоматизированное дистанционное обучение работников.',
		},
		{
			title: 'Инструктажи',
			caption: 'Модуль инструктажей соответствует законодательству страны об электронном документообороте. Использование электронной подписи при ведении журналов позволит организации сократить трудозатраты, избежать ошибок при заполнении журналов, избежать бумажного хранения в архивах.',
		},
		{
			title: 'Документы',
			caption: 'Данный модуль позволит руководителям добавлять, смотреть и распечатать нормативные документы, наряд-допуски и журналы по организации необходимые для выполнения производственных задач.',
		},
		{
			title: 'Наряд-допуск',
			caption: '- 100 % электронный документооборот;<br />- Уведомления ответственным лицам;<br />- Простое оформление и согласование;<br />- Отслеживание изменений.',
		},
		{
			title: 'Обратная связь',
			caption: 'Модуль позволит отслеживать волнующие вопросы от использования программы и реагировать на технические ошибки в программе.',
		},
	]

	return (
		<div className="landing-functions">
			<div className="landing-functions__padding" id='functions'></div>
			<h2 className="landing-functions__header">
				Функционал
			</h2>
			<div className="landing-functions__content">
				<div className="landing-functions__text">
					<p>Платформа состоит из Web-версии и мобильного приложения с добавлением более расширенного функционала. Программа разработана в соответствии с законодательством РФ.</p>
				</div>
				<div className="landing-functions__buttons">
					<TabBtn
						title='Личный QR-код'
						id={0}
						choise={choise}
						setChoise={setChoise}
					/>
					<TabBtn
						title='Обучение'
						id={1}
						choise={choise}
						setChoise={setChoise}
					/>
					<TabBtn
						title='Инструктажи'
						id={2}
						choise={choise}
						setChoise={setChoise}
					/>
					<TabBtn
						title='Документы'
						id={3}
						choise={choise}
						setChoise={setChoise}
					/>
					<TabBtn
						title='Наряд-допуск'
						id={4}
						choise={choise}
						setChoise={setChoise}
					/>
					<TabBtn
						title='Обратная связь'
						id={5}
						choise={choise}
						setChoise={setChoise}
					/>
				</div>
			</div>
			<Sample
				id={0}
				data={tabsText}
				choise={choise}
			/>
			<Sample
				id={1}
				data={tabsText}
				choise={choise}
			/>
			<Sample
				id={2}
				data={tabsText}
				choise={choise}
			/>
			<Sample
				id={3}
				data={tabsText}
				choise={choise}
			/>
			<Sample
				id={4}
				data={tabsText}
				choise={choise}
			/>
			<Sample
				id={5}
				data={tabsText}
				choise={choise}
			/>
		</div>
	)
}

const TabBtn = (props) => {

	const {
		choise,
		setChoise,
		id,
		title
	} = props

	const [active, setActive] = useState(false);

	useEffect(() => {
		if (choise === id) {
			setActive(true);
		} else {
			setActive(false);
		}
	}, [choise])

	return (
		<div
			className={`landing-functions__button ${active ? 'active' : ''}`}
			onClick={() => setChoise(id)}
		>
			{title}
		</div>
	)
}

const Sample = (props) => {

	const {
		id,
		choise,
		data
	} = props

	return (
		<div className={`landing-functions__samples ${choise !== id ? 'd-none' : ''}`}>
			<div className="landing-functions__info">
				<div className="landing-functions__info-header" >
					{data[id].title}
				</div>
				<div
					className="landing-functions__description"
					dangerouslySetInnerHTML={{ __html: `${data[id].caption}` }}
				/>
				<picture className="landing-functions__computer">
					<img src={`/img/functions-${id + 1}.svg`} />
				</picture>
				<picture className="landing-functions__mobile">
					<img src={`/img/functions-mob-${id + 1}.svg`} />
				</picture>
			</div>
		</div>
	)
}