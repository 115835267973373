import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://api.catm-landing.msharks.ru/api';

export const axiosInstance = axios.create({
	baseURL: API_URL,
	responseType: 'json',
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log(error);
	}
)

axiosInstance.interceptors.request.use(
	(config) =>
		new Promise((resolve) => {
			const newConfig = {
				headers: {},
				...config
			}

			const token = localStorage.getItem('token')
			if (token) {
				newConfig.headers['Authorization'] = 'Token ' + token
			}

			resolve(newConfig)
		}),
	(e) => Promise.reject(e)
)