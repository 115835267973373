import React from 'react'
import './style.css'

export default function Modal({ setModalView }) {
	return (
		<div className='modal-message'>
			<div className="modal-message__title">
				Ваше сообщение отправлено
			</div>
			<button
				className="btn-yellow modal-message__btn"
				onClick={() => setModalView(false)}
			>
				Ок
			</button>
		</div>
	)
}
